.products{
    /* margin: 20px;  */
    justify-content: center;
}
.part1{
    margin: 20px;
    box-sizing: border-box; 
    justify-content: center;
    background-color: rgba(5, 54, 89, 0.7);
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%; 
    height: 100%; 
}
.text1, .photo1{
    flex: 1;
    display: flex;
    max-width: 50%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;  
}
.text1{
    font-size: 25px ;
    letter-spacing: 5px;
    word-spacing: 5px;
    padding-left: 10px;
}
.text1 h1{
    padding-left: 8px;
}
.photo1{
    height: 100%;
}
.photo1 img{
    width: 100%;
    height: 100%;
}
.text2{
    margin-top: 20px;
    font-size: 25px;
}
.product-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    /* box-sizing: border-box;  */
    /* gap: 20px; */
    margin-right: 140px;
    margin-left: 140px;
    margin-bottom: 20px;
}
.product-container-text, .product-container-image{
    width: 50%;
    align-items: center;
    justify-content: center; 
}
.product-container-text{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 80px;
}
.text-heading{
    font-size: 35px;
    text-align: left;
}
.text-paragraph{
    font-size: 20px;
    width: 90%;

}
.btn{
    padding: 10px 20px;
    border-radius: 25px;
    width: 150px;
    margin: 15px 0px;
    border-color: #00B4EC;
    background-color: white;
    color: #00B4EC;  
}
.btn:hover{
    cursor: pointer;
    transform: scale(1.1);
}
.product-container-image {
    height: 300px;
}
.product-container-image img{
    width: 80%;
    height: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}
.git{
    width: 100%;
}

@media (max-width: 768px) {
    .products{
        margin: 20px; 
    }
    .part1{
        display: grid;
        margin: 0px;
        width: 100%;
    }
    .text1, .photo1{
        max-width: 100%;
        align-items: center;
        justify-content: center;  
        max-height: 400px;
    }
    .text1{
        font-size: 16px ;
        padding: 5px;
    }
    .text1 h1{
        padding-left: 0px;
    }
    .text2{
        text-align: center;
        margin-top: 150px;
        /* border: 1px solid black; */
        /* font-size: 16px; */
    }
    .text2 h1{
        font-size: 24px;
    }
    .product-container{
        display: grid;
        max-height: 600px;
        margin: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .product-container-text, .product-container-image{
        width: 100%;
    }
    .product-container-text{
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 0px;
    }
    .text-heading{
        font-size: 25px;
        text-align: center;
    }
    .text-paragraph{
        font-size: 16px;
        padding: 10px;
        margin-left: 5px;
        text-align: justify;
    }
    .btn-link{
        text-align: center;
    }
    .product-container-image img{
        width: 100%;
        border-radius: 50px;
    }
    .product-container-image {
        order: 1; /* Image first */
    }
    .product-container-text {
        order: 2; /* Text second */
    }
    /* .photo1{
        order: 1;
    }
    .text1{
        order:2
    } */
}