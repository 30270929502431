/* .product-range-container  {
    text-align: center;
    margin-top: 60px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 50px;
    padding-bottom: 50px ;
    padding-top: 20px;
    background-color: #f0f0f0;
  }
  .product-range-container h1{
    padding-bottom: 30px;
  }
   */
  /* .product-images{
    position: relative;
    width: 80%;
    height: 400px;
    margin: 0 auto;
  }
  .productdiv{
    padding: 0 25px;
    box-sizing: border-box;
    margin-bottom: 30px;
    height: 350px;
    object-fit: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
  }
  .productdiv img{
    object-fit: contain;
    border-radius: 50px;
    box-shadow: 0px 3px 15px black;
    background-position: center;
  }

  .slick-slide img {
    width: 100%;
    height: auto;
  }

  
  .slick-prev, .slick-next {
    width: 20px;
    height: 20px;
    background-color: rgba(80, 80, 80, 0.5); 
    border-radius:50%;
    display: flex;
    top: 48%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
  }
  
  .slick-prev {
    left: -40px;
  }
  
  .slick-next {
    right: -40px;
  }
  
  .recent-work{
    text-align: center;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 50px;
    padding-bottom: 50px ;
    padding-top: 20px;
    background-color: #f0f0f0;
  }
  .recent-work h1{
    padding-bottom: 30px;
  }
  
  .recentdiv{
    padding: 0 25px;
    box-sizing: border-box;
    margin-bottom: 20px;
    height: 100%;
    max-height: 400px;
  }
   .recent-work-images {
    position: relative;
    width: 70%;
    margin: 0 auto;
  }
  .recent-work-images img{
    border: 1px solid black;
    border-radius: 10px;
    max-height: 450px;
  } */
  --

  .product-range {
    text-align: center;
    background-color: #e0f0ff;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .product-range h1 {
    font-size: 2em;
    margin-bottom: 10pxv20px;
    padding: 10px 0px;
  }
  
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .product {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin: 20px 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  .product h2 {
    background-color: #0056b3;
    color: white;
    padding: 10px;
    height: 50px;
    border-radius: 8px 8px 0 0;
    margin: -20px -20px 10px -20px;
    text-align: center;
  }
  
  .product img {
    /* border: 2px solid black; */
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    margin: 10px 0px -10px 0px;
    width: 100%;
    height: 250px;
    border-radius: 8px;
  }
  
  /* .product ul {
    list-style-type: none;
    padding: 0;
  } */
/*   
  .product li {
    padding: 5px 0;
  }
   */
  
  @media (max-width: 768px) {
    .products {
      flex-direction: column;
      align-items: center;
    }
    .product-range-container h1{
        font-size: x-large;
        padding-bottom: 10px;
    }
    .product-range-container {
        text-align: center;
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        padding-bottom: 40px;
        background-color: cornflowerblue;
      }
    .product-images img{
        border: 1px solid black;
        border-radius: 10px;
      }
    .product-images {
        position: relative;
        width: 90%;
        margin: 0 auto;
      }
    .productdiv{
        padding: 0 5px;
        box-sizing: border-box;
        margin-bottom: 20px;
      }
   .slick-prev, .slick-next {
    display: none;
   }
    .recent-work{
      margin: 10px;
      padding-bottom: 50px ;
      padding-top: 10px;
    }
    .recent-work h1{
      padding-bottom: 10px;
      font-size: x-large;
    }
    
    .recentdiv{
      margin-bottom: 20px;
      max-height: 150px;
    }
     .recent-work-images {
      position: relative;
      width: 80%;
      margin: 0 auto;
    }
    .recent-work-images img{
      border: 1px solid black;
      border-radius: 10px;
      max-height: 150px;
    }
  }
  