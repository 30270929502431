.home{
    margin-top: 20px;
}

.image{
    width: 100%;
    max-height: 700px;
    text-align: center; 
}
.image img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: contain;
    background-color: aliceblue;
    padding-bottom: 20px;
}
.slide{
    margin-left: 40px;
    margin-right: 40px;
}

/* Styling for slick-dots */
.slick-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}
.slick-dots li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}
.slick-dots li button {
    font-size: 10px;
    line-height: 15px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
}
.slick-dots li.slick-active button {
    background-color: #ccc;
    color: #fff;
}
.hometext1{
    margin-top: 60px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
    background-color: aliceblue;
    padding: 20px;
}
.hometext1 h1{
    padding-bottom: 30px;
}
.hometext1 p{
    font-size: 18px;
    letter-spacing: 2px;
    justify-content: center;
}


@media (max-width: 768px) {
    /* Adjust image height for smaller screens */
    .image {
        max-height: 220px;
        max-width: 100%;
    }
    .image img {
        width: 100%;
        height: 250px;
        padding-bottom: 20px;
    }
    .slide{
        margin-left: 10px;
        margin-right: 10px;
    }
    .hometext1{
        margin-left: 10px;
        margin-right: 10px;
    }
    .hometext1 h1{
        font-size: larger;
        padding-bottom: 10px;
    }
    .hometext1 p{
        font-size: 12px;
        letter-spacing: 1px;
    }
  }