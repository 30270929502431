.about-us {
  margin: 20px;
  position: relative;    
  align-items: center;
  justify-content: center;
}
.image-container {
  position: relative;
  height: 500px;
  display: flex;
  width: 100%;
} 
.bgimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7; 
  border-radius: 10px;
} 
.text-overlay {
  position: absolute;
  bottom: 30px;
  left: 3%;
  right: 3%;
  color: white;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 10px;
  max-width: 100%;
} 
.text-overlay h1 {
  margin-bottom: 10px;
  font-size: 45px;
}
.about-header{
  font-size: 40px;
  margin: 40px;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}

.about-us2{
  position: relative;
  height: 500px;
  display: flex;
  width: 100%;
}
.bgimage2{
  opacity: 0.1;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
  object-fit: cover;
}
.text-overlay2{
  position: absolute;
  display: flex;
  max-height: 420px;
  margin: 20px;
  left: 3%;
  right: 3%;
  color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
}
.abt-logo img{
  max-height: 100%;
}
.abt-text{
  margin-left: 40px;
}
.abt-text p{
  color: black;
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  padding: 0px 10px;
}
.divider{
  width: 50px;
  height: 2px;
  background-color: #00c3ff;
  margin: 10px auto 50px;
}
.vision {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; 
}
.vision-box {
  background-color: #f3f7f9;
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  position: relative;
  margin: 20px;
}
.vision-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.vision-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.vision-text{
  padding: 15px 10px;
}
.heading1{
  font-size: 25px;
  padding-top:20px ;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.para1{
  /* padding-top: 10px; */
  padding: 0px 15px;
  letter-spacing: 1px;
  font-size: 17px;
  /* text-align: justify; */
  word-spacing: 2px;
}

.choose-me {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
}
.choose-box {
  width: 350px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
}
.choose-icon{
  margin-right: 10px;
  margin-left: 20px;
}
.choose-icon img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.choose-text {
  font-size: 20px;
  margin-left: 10px ;
}

@media(max-width:768px){
  .about-us {
    margin: 10px;  
  }
  .image-container {
    height: 250px;
  }
  .text-overlay {
    bottom: 20px;
    padding: 3px;
  } 
  .text-overlay h1 {
    margin-bottom: 10px;
    font-size: 25px;
  }
  .about-header{
    font-size: 40px;
    margin: 30px;
  }
  .about-us2{
    position: relative;
    width: 100%;
    height: 1000px;
  }
  .bgimage2{
    position: absolute;
    border: 3px solid blue;
    max-width: 100%;
    height: 100%;
    opacity: 0.2;
    border-radius: 10px;
    object-fit: cover;
  }
  .text-overlay2{
    padding: 5px;
    display: flex;
    flex-direction:column;
    margin: 5px;
    max-height: 100%;
    left: 0;
    right: 0;
  }
  
  .abt-logo{
    height: 250px;
    justify-content: center;
    padding-right: 0px;
  }
  .abt-text{
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .abt-text p{
    font-size: 16px;
    padding: 0px 10px;
    text-align: justify;
  }
   .vision {
    flex-wrap: wrap;/* Allows wrapping to a new row if there are too many items to fit in one line */
     margin: 10px;
  }
  .vision-box {
    width: 100%;
    height: 100%;
    margin-top: 60px;
    border-radius: 10px; /* Rounds the corners slightly */
   }
  .vision-text{
    padding: 10px;
  }
  .choose-me {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 0px;
    width: 100%;
  }
  .choose-box {
    width: 100%;
    height: 70px;
    overflow: hidden;
    /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); */
    padding: 0px;
    text-align: center;
  }
  .choose-icon{
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .choose-icon img {
    width: 80px;
    height: 100%;
    border-radius: 50px;
  }
  .choose-text {
    font-size: 20px;
    margin-left: 10px ;
    margin-right: 5px;
  }
}